<template>
  <AffiliateLayout>
    <!-- ORDER HISTORY -->
    <b-card>
      <h4 class="text-primary mb-2">
        Lịch sử đơn hàng
      </h4>

      <div class="d-flex">
        <b-input-group class="mb-2">
          <b-form-input
            v-model="orderHistorySearchTerm"
            placeholder="Nhập mã đơn..."
          />
          <b-input-group-append>
            <b-button variant="outline-primary">
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>

      <div class="history-grid">
        <div class="history-grid__item">
          <div>
            <feather-icon
              icon="ShoppingBagIcon"
              size="32"
              class="text-primary"
            />
          </div>
          <small>Tổng đơn hàng</small>
          <h4 class="text-warning mb-0">
            {{ orderHistoryBox ? orderHistoryBox.total_order : 0 }}
          </h4>
        </div>
        <div class="history-grid__item">
          <div>
            <feather-icon
              icon="DollarSignIcon"
              size="32"
              class="text-primary"
            />
          </div>
          <small>Tổng doanh thu</small>
          <h4 class="text-warning mb-0">
            {{ unitFormatOriginal(orderHistoryBox ? orderHistoryBox.revenue : 0) }}
          </h4>
        </div>
        <div class="history-grid__item">
          <div>
            <feather-icon
              icon="DollarSignIcon"
              size="32"
              class="text-primary"
            />
          </div>
          <small>Tổng lợi nhuận</small>
          <h4 class="text-warning mb-0">
            {{ unitFormatOriginal(orderHistoryBox ? orderHistoryBox.profit : 0) }}
          </h4>
        </div>
        <div class="history-grid__item">
          <div>
            <feather-icon
              icon="XCircleIcon"
              size="32"
              class="text-primary"
            />
          </div>
          <small>Đơn hàng hủy</small>
          <h4 class="text-warning mb-0">
            {{ orderHistoryBox ? orderHistoryBox.order_cancel : 0 }}
          </h4>
        </div>
        <div class="history-grid__item">
          <div>
            <feather-icon
              icon="TrendingUpIcon"
              size="32"
              class="text-primary"
            />
          </div>
          <small>Tỷ lệ chuyển đổi</small>
          <h4 class="text-warning mb-0">
            2%
          </h4>
        </div>
      </div>

      <vue-good-table
        class="custom-good-table mt-2"
        style-class="vgt-table"
        :columns="orderHistoryFields"
        :rows="orderHistoryItems"
        :search-options="{
          enabled: true,
          externalQuery: orderHistorySearchTerm
        }"
        :pagination-options="{
          enabled: true,
          perPage:orderHistoryPageLength
        }"
        :line-numbers="true"
      >
        <!-- Table: Head -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span>
            {{ $t(props.column.label) }}
          </span>
        </template>

        <!-- Table: Rows -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: order_no -->
          <span
            v-if="props.column.field === 'order_no'"
            class="d-inline-flex flex-wrap align-items-center"
          >
            <!-- <span
              class="line-clamp"
              style="max-width: 320px;"
            >
              #{{ props.row.order_no }}
            </span> -->
            <b-link
              class="line-clamp"
              style="max-width: 320px;"
              :to="`/order/edit/${props.row.id_encode}`"
            >
              #{{ props.row.order_no }}
            </b-link>
          </span>

          <!-- Column: name -->
          <span
            v-else-if="props.column.field === 'name'"
            class="d-inline-flex flex-wrap align-items-center"
          >
            <span
              v-b-tooltip.hover.top="props.row.name"
              class="line-clamp"
              style="max-width: 320px;"
              :title="props.row.name"
            >
              {{ props.row.name || '-' }}
            </span>
          </span>

          <!-- Column: created_at -->
          <span
            v-else-if="props.column.field === 'created_at'"
            class="d-inline-flex flex-wrap align-items-center"
          >
            <span
              class="line-clamp"
              style="max-width: 320px;"
            >
              {{ dateFormat(props.row.created_at) }}
            </span>
          </span>

          <!-- Column: quantity -->
          <span
            v-else-if="props.column.field === 'quantity'"
            class="d-inline-flex flex-wrap align-items-center"
          >
            <span
              class="line-clamp"
              style="max-width: 320px;"
            >
              {{ props.row.quantity }}
            </span>
          </span>

          <!-- Column: total -->
          <span
            v-else-if="props.column.field === 'total'"
            class="d-inline-flex flex-wrap align-items-center"
          >
            <span
              class="line-clamp"
              style="max-width: 320px;"
            >
              {{ unitFormatOriginal(Number(props.row.total)) }}
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap p-2">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing
              </span>
              <b-form-select
                v-model="orderHistoryPageLength"
                :options="['10','30','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="orderHistoryPageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </AffiliateLayout>
</template>

<script>
import {
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormSelect,
  BButton,
  BPagination,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

import MixinAffiliate from '../mixin'
import AffiliateLayout from '../layouts/AffiliateLayout.vue'

export default {
  name: 'MemberDetail',
  components: {
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormSelect,
    BButton,
    BPagination,
    BLink,
    VueGoodTable,
    AffiliateLayout,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [MixinAffiliate],
  props: {},

  data() {
    return {
      orderHistoryFields: [
        {
          field: 'order_no', label: 'Mã đơn hàng', sortable: false, thClass: 'text-left',
        },
        {
          field: 'name', label: 'Chi tiết đơn', sortable: false, thClass: 'text-left',
        },
        {
          field: 'created_at', label: 'Thời gian đặt', sortable: true, thClass: 'text-left', tdClass: 'text-left',
        },
        {
          field: 'quantity', label: 'Số lượng', sortable: false, tdClass: 'text-center',
        },
        {
          field: 'total', label: 'Tổng doanh thu', sortable: false, tdClass: 'text-right',
        },
      ],
      orderHistoryItems: [],
      orderHistoryPageLength: 10,
      orderHistorySearchTerm: '',
      orderHistoryBox: null,
    }
  },
  created() {
    this.loadOrderList()
  },
  methods: {
    statusClass(status) {
      let result = ''
      switch (status) {
        case 1:
          result = 'text-warning'
          break
        case 2:
          result = 'text-success'
          break
        case 3:
          result = 'text-danger'
          break
        default:
          result = 'text-warning'
          break
      }
      return result
    },
    async loadOrderList() {
      const d = new Date()
      const time = d.getTime()
      const d2 = new Date(time - 31556926000)
      const startDate = `${d2.getFullYear()}-${d2.getMonth() + 1}-${d2.getDate()}`
      const endDate = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`

      const res = await MixinAffiliate.get('/api/v1/auth/orders', { 'sort[]': 'id,desc' })
      const res2 = await MixinAffiliate.get('/api/v1/auth/dashboard_box_operating_margin', { date_from: startDate, date_to: endDate })
      if (res.status) {
        const result = []
        res.data.items.map(item => {
          result.push({
            ...item,
            created_at: item.created_at.slice(0, 10),
          })
          return false
        })
        this.orderHistoryItems = result
      }
      if (res2.status) {
        this.orderHistoryBox = res2.data
      }
    },
  },
}
</script>

<style lang="scss">
@import url(../member/member.scss);
.img-avata{
  background-color: #f0f0f0;
  padding: 16px;
  text-align: center;
  img{
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
  }
}
</style>
